<template>
  <div>
    <v-radio-group
      v-model="computedType"
      :column="false"
    >
      <v-radio
        :label="$t(`t.Me`)"
        value="me"
        class="mr-4"
      ></v-radio>
      <v-radio
        :label="$t(`t.Specific`)"
        value="specific"
      ></v-radio>
    </v-radio-group>
    <picker
      v-if="computedType === 'specific'"
      document-types="users"
      :selected-id.sync="computedUser"
      :clearable="false"
      :attach="false"
    />
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  computed: {
    computedUser: {
      get () { return this.dataUser },
      set (v) {
        this.dataUser = v
        this.dataDocumentEmit()
      }
    },
    computedType: {
      get () { return this.dataType },
      set (v) {
        this.dataType = v

        if (v === 'me') {
          this.dataUser = null
        }
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      required: [
        v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')
      ],
      dataUser: null,
      dataType: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  methods: {
    emitDocument () {
      const doc = this.dataType === 'me' ? 'me' : { userId: this.dataUser }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', doc)
      }
    }
  },
  props: {
    document: [Object, String],
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        this.dataType = v === 'me' ? 'me' : 'specific'
        this.dataUser = v?.userId ?? null
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
